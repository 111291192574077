// Import styles
@import '~@coreui/coreui/scss/bootstrap.scss';
@import '~@coreui/coreui/scss/coreui.scss';

@import '~@coreui/icons/css/all';

// Import simple line icons
@import '~simple-line-icons/scss/simple-line-icons';

// Import fontawesome icons
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';
@import '~@fortawesome/fontawesome-free/scss/v4-shims.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';

// Import Toastr styles
@import "~toastr/build/toastr.min.css";

.card-header {
  background-color: #3399ff21;
}

.print-only {
  display: none !important;
}
@import "print";


.pre-new-lines-formatter {
  white-space: pre-wrap; /* Preserve line breaks */
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
}