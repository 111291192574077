@media print {
  .app-footer,
  .c-header,
  .c-sidebar,
  .no-print {
    display:none !important;
  }
  .print-only {
    display: flex !important;
  }
  .card {
    border: none !important;
  }
}
